export const columns = [
  {
    title: '经销商名称',
    dataIndex: 'dealerName',
  },
  {
    title: '自动合成视频数',
    dataIndex: 'awemeCount',
  },
  {
    title: '已发布视频数',
    dataIndex: 'publishCount',
  },
  {
    title: '操作',
    width: 200,
    scopedSlots: { customRender: 'action' },
  },
];
