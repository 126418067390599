<template>
  <div class="prompt-debug-list">
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row>
        <a-col :span="5">
          <a-form-model-item label="经销商名称">
            <a-input v-model="form.dealerName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-space style="margin-top: 4px; margin-left: 10px">
            <a-button type="primary" @click="getTableData(true)">查询</a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="data-table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :row-key="(record, index) => index"
        :row-selection="rowSelection"
      >
        <div slot="action" slot-scope="record">
          <a @click="toDetail(record)">查看自动合成视频 </a>
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :options="['100']"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/autoVideoDealer.js';
import { columns } from './constants';

export default {
  components: {},
  data() {
    return {
      columns,
      loading: false,
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      tableData: [],
      form: {
        dealerName: '',
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
    };
  },
  mounted() {
    this.getTableData(true);
  },
  methods: {
    getTableData(isFirst) {
      this.loading = true;
      const params = {
        ...this.form,
        page: isFirst ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      api
        .getVideoDealerList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    toDetail(record) {
      const route = this.$router.resolve({
        name: 'autoVideoLib',
        query: {
          dealerName: record.dealerName,
        },
      });
      window.open(route.href);
    },
  },
};
</script>

<style lang="less"></style>
